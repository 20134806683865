import React, { useState,useRef, useEffect } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Zoom from 'react-medium-image-zoom'
import Layout from "../components/layout"
import PopuVideo from "../components/PopuVideo"
import AOS from 'aos'
import 'aos/dist/aos.css'
import Button from "../components/styles/Button"

import 'react-medium-image-zoom/dist/styles.css'
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/swiper-bundle.css'

export default function ProductList({pageContext: { node },location}) {
    const [headerColor,setHeaderColor] = useState(`bg-transparent ${node.topBanner.textColor?'text-black':'text-white'}`)
    
    useEffect(()=>{
        AOS.init()
    },[])   
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="relative pt-[190px] h-screen">
                <GatsbyImage className="w-full h-full !absolute left-0 top-0" image={getImage(node.topBanner.picture.localFile)} alt="banner"/>
                <div className={`container mx-auto relative z-[1] ${node.topBanner.textColor?'text-black':'text-white'}`}>
                    <h1 className="text-[40px] leading-snug pb-[60px]">{node.topBanner.title}</h1>
                    <ul>
                    {
                        node.topBanner.list.map((n,i)=>{
                            return <li key={i} className="py-[5px]">{n.text}</li>
                        })
                    }
                    </ul>
                </div>
            </div>
            <div className="container mx-auto flex items-start justify-between pt-10 pb-[328px] xl:flex-col xl:pb-[190px] sm:pb-24">
                <h2 className="text-[40px] leading-snug xl:pb-4">{node.solutions[0].title}</h2>
                <p className="max-w-[944px] xl:max-w-full">{node.solutions[0].description}</p>
            </div>
            
            <div className="container mx-auto pb-[380px] fun xl:pb-[180px] sm:pb-28">
                <h2 data-aos="fade-down" data-aos-duration="800" className="text--karaoke text-[120px] text-gray-light leading-snug pb-10 xl:text-[100px]">{node.functions[0].title}</h2>
                <div data-aos="fade-up" data-aos-duration="600" className="flex justify-end">
                    <p className="text-[40px] leading-snug max-w-[944px] xl:text-3xl">{node.functions[0].description}</p>
                </div>
            </div>
            
            <div className="max-w-[1672px] w-full px-4 mx-auto overflow-hidden">
                <Swiper 
                initialSlide = {1}
                slidesPerView={'auto'} 
                slideToClickedSlide={true} 
                centeredSlides={true} 
                spaceBetween={20}
                loop={true}
                className="w-[1100px] xl:w-full swiper-wrap !overflow-visible"
                >
                {
                    node.carousel[0].image.map((n,i,a)=>{
                        return <SwiperSlide key={i}>
                                {({ isActive }) => (
                                    <>
                                        {isActive?
                                            <>
                                            <Zoom overlayBgColorEnd={'rgba(0,0,0,0.68)'}><GatsbyImage className="w-full" image={getImage(n.image.localFile)} alt="product"/></Zoom><div className="pt-2.5">0{i+1}/0{a.length} <span className="pl-3">{n.title}</span></div>
                                            </>:
                                            <GatsbyImage className="w-full" image={getImage(n.image.localFile)} alt="product"/>
                                        }
                                        
                                    </>
                                )}
                            </SwiperSlide>
                    })
                }
                    
                </Swiper>
            </div>
            
            <div className="container mx-auto flex items-start justify-between py-[100px] xl:flex-col">
                <h2 className="text-[40px] leading-snug xl:pb-4">{node.solutions[1].title}</h2>
                <p className="max-w-[944px] xl:max-w-full">{node.solutions[1].description}</p>
            </div>
            <figure><img className="w-full object-cover" src={`https://admin.cnsotto.com${node.middleBanner.url}`} alt="product"/></figure>
          
            <div className="container mx-auto pt-[182px] pb-[410px] xl:pb-[200px] sm:pt-24 sm:pb-28">
                <h2 data-aos="fade-down" data-aos-duration="800" className="text--karaoke text-[120px] leading-snug pb-10 text-gray-light xl:text-[100px]">{node.functions[1].title}</h2>
                
                <div data-aos="fade-up" data-aos-duration="600" className="flex justify-end">
                    <p className="text-[40px] leading-snug max-w-[944px] xl:text-3xl">{node.functions[1].description}</p>
                </div>
            </div>
            <div className="max-w-[1672px] w-full px-4 mx-auto h-[920px] relative xl:h-[580px] sm:h-[376px]">
                <PopuVideo video={node.video.link} bg={node.video.background.localFile}/>
            </div>
            <div className="container mx-auto flex items-start justify-between pt-10 pb-[406px] xl:flex-col xl:pb-[200px] sm:pb-28">
                <h2 className="text-[40px] leading-snug xl:pb-4">{node.solutions[2].title}</h2>
                <p className="max-w-[944px]">{node.solutions[2].description}</p>
            </div>
            <div className="container mx-auto pb-[356px] xl:pb-[180px] sm:pb-24">
                <h2 data-aos="fade-down" data-aos-duration="800" className="text--karaoke text-[120px] leading-snug pb-10 text-gray-light xl:text-[100px]">{node.functions[2].title}</h2>
                <div data-aos="fade-up" data-aos-duration="600" className="flex justify-end">
                    <p className="text-[40px] leading-snug max-w-[944px] xl:text-3xl">{node.functions[2].description}</p>
                </div>
            </div>

            <div className="max-w-[1472px] flex items-start justify-between w-full px-4 mx-auto xl:flex-col">
                <h3 className="text-[40px] leading-snug pb-10">行业应用</h3>
                <div className="grid grid-cols-4 gap-12 max-w-[944px] w-full xl:max-w-full lg:grid-cols-3 sm:grid-cols-2 sm:gap-6">
                    {
                        node.industry.map((n,i)=>{
                            return <a href={n.link} key={i}><figure className="border border-gray hover:border-black rounded-sm overflow-hidden"><img className="w-full h-full object-cover mx-auto bg-light transform transition-all duration-1000 ease-linear hover:scale-110" src={`https://admin.cnsotto.com${n.image.url}`} alt="ico"/></figure></a>
                        })  
                    }
                    
                </div>
            </div>
            <div className="container mx-auto pt-[100px] pb-[140px] sm:pb-24">
                <h3 className="text-[40px] leading-snug pb-[30px]">{node.topBanner.title}产品</h3>
                <div className="grid grid-cols-3 gap-x-[45px] gap-y-[100px] text-center md:grid-cols-2 sm:grid-cols-1">
                    {
                        node.product_details.map((n,i)=>{
                            return <div key={i}>
                                        <figure className="h-[330px] overflow-hidden"><img className="w-full h-full object-cover bg-light transform transition-all duration-1000 ease-linear hover:scale-110" src={`https://admin.cnsotto.com${n.seo.shareImage.url}`} alt="product"/></figure>
                                        <div className="text-3xl pb-[30px] pt-5">{n.title}</div>
                                        <Button variant="black" className="mx-auto" href={n.url}>了解更多</Button>
                                    </div>
                        })  
                    }
                    
                </div>
            </div>
            <div className="flex max-w-[1672px] w-full px-4 mb-[70px] mx-auto xl:flex-col">
                <div className="bg-black text-white text-[40px] leading-snug w-[595px] pt-[56px] pr-[215px] pb-[72px] pl-[100px] xl:w-full xl:px-5">{node.bottomBlock.text.title}</div>
                <div className="bg-light pt-[56px] px-[68px] pb-[48px] w-[calc(100%-595px)] xl:w-full xl:px-5">
                    <p className="pb-[55px] text-3xl truncate">{node.bottomBlock.text.info}</p>
                    <Button variant="black" href={node.bottomBlock.button.url}>{node.bottomBlock.button.name}</Button>
                </div>
            </div>
        </Layout>
    )
}

